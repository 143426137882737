.home-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #c7ede6; */
  background-color: #d9e0d0;
}

.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: #d9e0d0;
  padding-bottom: 20px;
  z-index: 9;
}

.title {
  position: relative;
  margin: auto;
  font-size: 24px;
  font-weight: 600;
  word-spacing: 0.2em;
  display: inline-block;
  white-space: nowrap;
  color: transparent;
  background-color: #F26E23;
  background-clip: text;
  margin-top: 60px;
}

.title::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-image: linear-gradient(120deg, transparent 0%, transparent 6rem, white 11rem, transparent 11.15rem, transparent 15rem, rgba(255, 255, 255, 0.3) 20rem, transparent 25rem, transparent 27rem, rgba(255, 255, 255, 0.6) 32rem, white 33rem, rgba(255, 255, 255, 0.3) 33.15rem, transparent 38rem, transparent 40rem, rgba(255, 255, 255, 0.3) 45rem, transparent 50rem, transparent 100%);
  background-clip: text;
  background-size: 150% 100%;
  background-repeat: no-repeat;
  animation: shine 5s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.title-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 10px;
  right: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.letter-show {
  animation: letterShow 3s linear forwards;
}

@keyframes letterShow {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.letter-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.logo {
  width: 120px;
  height: 120px;
}

.tip {
  font-size: 14px;
  color: #2a5774;
  font-weight: 400;
  margin-top: 10px;
  text-shadow: 
        0 0 10px #d96f33,
        0 0 20px #d96f33,
        0 0 50px #d96f33,
        0 0 100px #d96f33,
        0 0 200px #d96f33;
}

.eggs {
  position: absolute;
  font-size: 16px;
  bottom: 30px;
  color: #2a5774;
  text-shadow: 0 0 10px #d96f33;
}

.name-box {
  position: relative;
  width: 100%;
  height: 60px;
  filter: contrast(20);
  overflow: hidden;
}

.name {
  width: 100%;
  color: #392f29;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 60px;
  animation: letterspacing 3s infinite alternate ease-in-out;
  display: block;
  position: absolute;
  text-align: center;
}

@keyframes letterspacing {
  0% {
    letter-spacing: 1px;
    opacity: 0;
  }

  100% {
    letter-spacing: 3px;
    opacity: 0.8;
  }
}

.letter {
  width: 100%;
}
